import { useState, ChangeEvent, FocusEvent, KeyboardEvent } from 'react';
import Form from 'react-bootstrap/Form';
import styles from './text-input.module.css';
interface OnChangeFunc {
  (props: object): void;
}

interface TextInputProps {
  error: boolean;
  errorMessage: string;
  label: string;
  maxLength?: number;
  name: string;
  onBlur?: OnChangeFunc;
  onChange?: OnChangeFunc;
  onKeyUp?: OnChangeFunc;
  placeholder: string;
  required: boolean;
  t: any;
}

export default function TextInput({
  error,
  errorMessage,
  label,
  maxLength,
  name,
  onBlur,
  onChange,
  onKeyUp,
  placeholder,
  required,
  t,
}: TextInputProps) {
  const [inputValue, setInputValue] = useState<string>('');
  const [valid, setValid] = useState<boolean>();
  const [invalid, setInvalid] = useState<boolean>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValid(false);
    setInvalid(false);
    setInputValue(value);
    if (onChange) {
      onChange({ [name]: value });
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (onBlur) {
      onBlur({ [name]: value });
    }
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyUp) {
      onKeyUp(e.target);    
    }
  };

  return (
    <Form.Group className={styles.formGroup} controlId={name}>
      <Form.Label className={styles.label}>{t(label)}</Form.Label>
      <Form.Control
        className={styles.formControl}
        isInvalid={invalid || error}
        isValid={valid}
        maxLength={maxLength}
        name={name}
        onBlur={(e: FocusEvent<HTMLInputElement>) => handleBlur(e)}
        onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => handleKeyUp(e)}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
        placeholder={placeholder}
        required={required}
        type='text'
        value={inputValue}
      ></Form.Control>
      <div className={styles.errorMessage} role='alert' aria-live='assertive'>
        {invalid || error ? t(errorMessage) : null}
      </div>
    </Form.Group>
  );
}
