import React, { FunctionComponent } from 'react';
import Layout from '../Layout';
import { useTranslation } from 'react-i18next';
import styles from './thank-you.module.css';
import circle from '../../assets/images/ic-checkmark-circle.svg';

export const ThankYou: FunctionComponent = () => {
  const { t } = useTranslation('common');
  return (
    <Layout>
      <div className={styles.wrapper}>
        <div className={styles.main}>
          <img
            src={circle}
            className={styles.image}
            width='130'
            alt=''
          />
          <br />
          <h1 className={styles.title}>{t('SUCCESS')}!</h1>
          <p className={styles.message}>
            {t('THANK_YOU_MESSAGE')}{' '}
            <a
              className={styles.link}
              href='mailto:technicalservices@propellerhealth.com'
            >
              technicalservices@propellerhealth.com
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default ThankYou;
