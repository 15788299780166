import React, { FunctionComponent } from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  getDataAgreement,
  getTermsAndConditions,
  getPrivacyPolicy,
} from '../../utils/terms';

const termsStyle = {
  iframe: {
    border: 'none',
    height: '85vh',
    overFlowY: 'scroll',
    width: '90%',
  },
};

export const Terms: FunctionComponent<{
  consent: string;
  locale: string;
  t: any;
}> = ({ consent, locale, t }) => {
  function getPath(): string | undefined {
    switch (consent) {
      case 'USER_AGREEMENT':
        return getTermsAndConditions(locale);
      case 'PRIVACY_POLICY':
        return getPrivacyPolicy(locale);
      case 'DATA_AGREEMENT':
        return getDataAgreement(locale);
      default:
        return getTermsAndConditions(locale);
    }
  }

  return (
    <div data-testid='terms-modal'>
      <Modal.Header closeButton>
        <Modal.Title>{t(consent)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe
          title={consent}
          style={termsStyle.iframe}
          src={getPath()}
        ></iframe>
      </Modal.Body>
    </div>
  );
};

export default Terms;
