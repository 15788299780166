import React, {
  FunctionComponent,
  MouseEvent,
  useState,
  useEffect,
} from 'react';
import TextInput from '../TextInput/TextInput';
import Button from 'react-bootstrap/Button';
import { ContactInfo } from '../../types/contactInfo';
import { validateField } from '../../utils/validation';
import { getPrivacyPolicy } from '../../utils/terms';
import { track } from '../../utils/segment';
import styles from './contact-form.module.css';
import { GroupConfig } from '../../types/groupConfig';
import { Trans, useTranslation } from 'react-i18next';
import Select from '../Select/Select';

interface AC_TYPE_OPTION {
  label: string;
  value: string
}
interface Field {
  type: string;
  error_message: string;
  label: string;
  maxLength: number;
  name: string;
  placeholder: string;
  required: boolean;
  options?: AC_TYPE_OPTION[];
}

export const ContactForm: FunctionComponent<{
  fields: Field[];
  goNext: () => void;
  groupConfig?: GroupConfig;
}> = ({ fields, goNext, groupConfig }) => {
  const [contactInfo, setContactInfo] = useState<ContactInfo>({
    first_name: '',
    last_name: '',
    title: '',
    email: '',
    retype_email: '',
    Propeller_Code__c: '',
    Account_Type__c: '',
    Clinic_Name__c: '',
    Site_ID__c: ''
  });

  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [touched, setTouched] = useState<string[]>([]);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const { i18n, t } = useTranslation('common');
  const isBlured = React.useRef<boolean>();

  useEffect(() => {
    const noErrors = errors.length === 0;
    if (isSubmitting) {
      if (noErrors) {
        setSubmitting(false);
        goNext();
      } else {
        setSubmitting(false);
      }
    }
  }, [errors, goNext, isSubmitting]);

  useEffect(() => {
    if(isBlured.current) validateAll();
    // eslint-disable-next-line
  }, [contactInfo.Account_Type__c]);

  const validateAll = () => {
    for (const field in contactInfo) {
      const obj = { [field]: contactInfo[field] };
      const valid = validateField(obj, contactInfo);
      if (!valid) {
        setErrors((prev) => [...prev, field]);
        setButtonDisabled(true);
        return;
      }
    }
    setButtonDisabled(false);
  };

  const clearError = (props: object) => {
    const key = Object.keys(props)[0];
    const cleared = errors.filter((item) => item !== key);
    setErrors([...cleared]);
  };

  const handleChange = (props: object) => {
    clearError(props);
    setContactInfo({ ...contactInfo, ...props });
  };

  const handleBlur = (field: object) => {
    isBlured.current = true;
    const key = Object.keys(field)[0];
    if (!touched.includes(key)) {
      setTouched([...touched, key]);
    }

    validateAll();
  };

const handleKeyUp = (field: object) => {
    const key = Object.keys(field)[0];
    if (!touched.includes(key)) {
      setTouched([...touched, key]);
    }

    validateAll();
  };
  const handleNext = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    track('next');
    validateAll();
    setSubmitting(true);
  };

  const program = groupConfig?.displayName || '';

  return (
    <div className={styles.contactForm}>
      <div className={styles.wrapper}>
        <h1>{t('HEADLINE')}</h1>
        <p className={styles.subtext}>{t('WELCOME', { program: program })}</p>
        <p className={styles.subtext}>
          <Trans i18nKey='NOTE'>
            <strong>Note:</strong> This form must be completed directly by the
            enrolling provider.
          </Trans>
        </p>
        {fields.map((field) => {
          return field.type === 'text' ? (
            <TextInput
              error={errors.includes(field.name)}
              errorMessage={field.error_message}
              key={field.name}
              label={field.label}
              maxLength={field.maxLength}
              name={field.name}
              onBlur={handleBlur}
              onChange={handleChange}
              onKeyUp={handleKeyUp}
              placeholder={field.placeholder}
              required={field.required}
              t={t}
            />
          ): (
            <Select
              options={ field.options ? field.options : [] }
              error={errors.includes(field.name)}
              errorMessage={field.error_message}
              key={field.name}
              label={field.label}
              name={field.name}
              onChange={handleChange}
              required={field.required}
              t={t}
            />
          );
        })}
        <p>
          <Trans i18nKey='PRIVACY_POLICY_LINK'>
            Our privacy policy can be found
            <a
              className={styles.link}
              href={getPrivacyPolicy(i18n.language)}
              target='_blank'
              rel='noopener noreferrer'
            >
              here
            </a>
          </Trans>
        </p>
        <div className={styles.buttonWrapper}>
            <Button
            type='submit'
            data-testid='submit-button'
            className={styles.submitButton}
            variant='success'
            onClick={handleNext}
            disabled={buttonDisabled}>
                {t('NEXT')}
            </Button>
        </div>
        <br />
        <p className={styles.subtext}>
          <Trans i18nKey='SUBTEXT'>
            If this is not your program or you reached this page in error, reach
            out to{' '}
            <a
              className={styles.link}
              href='mailto:technicalservices@propellerhealth.com'
            >
              technicalservices@propellerhealth.com
            </a>
            . If you are a healthcare provider who is not yet part of a program
            but you’d like to learn more about Propeller, email
            <a
              className={styles.link}
              href='mailto:provider@propellerhealth.com'
            >
              provider@propellerhealth.com
            </a>
          </Trans>
        </p>
      </div>
    </div>
  );
};

export default ContactForm;
