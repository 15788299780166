import React, { FunctionComponent, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Terms from '../Terms/Terms';
import { track } from '../../utils/segment';
import styles from './consent-form.module.css';
import { GroupConfig } from '../../types/groupConfig';
import { useTranslation, Trans } from 'react-i18next';

export const ConsentForm: FunctionComponent<{
  groupConfig?: GroupConfig;
}> = ({ groupConfig }) => {
  const [show, setShow] = useState(false);
  const [agreement, setAgreement] = useState('');
  const { i18n, t } = useTranslation('common');

  const handleClose = () => setShow(false);
  const handleShow = (agreementName: string) => {
    setAgreement(agreementName);
    setShow(true);
  };
  const country = groupConfig?.country || 'US';

  return (
    <div className={styles.consentForm}>
      <div className={styles.wrapper}>
        <h1>{t('USER_AGREEMENTS')}</h1>
        <p>{t('PLEASE_AGREE')}:</p>
        {
          <Form.Group controlId='agreements'>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Row>
                  <Col>
                    <input
                      id='User_Agreement__c'
                      name='User_Agreement__c'
                      value='true'
                      type='checkbox'
                      className={styles.customCheckbox}
                      required
                    />
                    <label htmlFor='User_Agreement__c' className={styles.label}>
                      <Trans i18nKey='CONSENT_USER_AGREEMENT'>
                        I accept the Propeller Health
                        <Button
                          className={styles.link}
                          variant='link'
                          onClick={() => {
                            handleShow('USER_AGREEMENT');
                          }}
                        >
                          Terms of Services
                        </Button>
                        .
                      </Trans>
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input
                      id='Privacy_Policy__c'
                      name='Privacy_Policy__c'
                      value='true'
                      type='checkbox'
                      className={styles.customCheckbox}
                      required
                    />
                    <label htmlFor='Privacy_Policy__c' className={styles.label}>
                      <Trans i18nKey='CONSENT_PRIVACY_POLICY'>
                        I declare that I have read and understood the Propeller
                        Health
                        <Button
                          className={styles.link}
                          variant='link'
                          onClick={() => {
                            handleShow('PRIVACY_POLICY');
                          }}
                        >
                          Privacy Notice
                        </Button>{' '}
                        for Providers.
                      </Trans>
                    </label>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={6}>
                {
                    (country === 'US' || country === 'CA') ? null :
                    (
                    <Row>
                    <Col>
                        <input
                        id='Data_Agreement__c'
                        name='Data_Agreement__c'
                        value='true'
                        type='checkbox'
                        className={styles.customCheckbox}
                        required
                        />
                        <label htmlFor='Data_Agreement__c' className={styles.label}>
                        <Trans i18nKey='CONSENT_DATA'>
                            I consent to the terms of the Propeller Health Data
                            Processing Agreement available
                            <Button
                            className={styles.link}
                            variant='link'
                            onClick={() => {
                                handleShow('DATA_AGREEMENT');
                            }}
                            >
                            here
                            </Button>{' '}
                            and understand my role as a Data Processor.
                        </Trans>
                        </label>
                    </Col>
                    </Row>
                    )
                }                
                <Row>
                  <Col>
                    <input
                      id='Marketing_Agreement__c'
                      name='Marketing_Agreement__c'
                      value='true'
                      type='checkbox'
                      className={styles.customCheckbox}
                    />
                    <label
                      htmlFor='Marketing_Agreement__c'
                      className={styles.label}
                    >
                      <Trans i18nKey='CONSENT_MARKETING'>
                        I consent to the processing by Propeller Health of my
                        personal data to send me marketing messages
                        electronically, as described in the
                        <Button
                          className={styles.link}
                          variant='link'
                          onClick={() => {
                            handleShow('PRIVACY_POLICY');
                          }}
                        >
                          Privacy Notice
                        </Button>
                        .
                      </Trans>
                    </label>
                  </Col>
                </Row>
              </Col>
            </Row>
            <br />
            <Button
              type='submit'
              data-testid='submit-button'
              className={styles.submitButton}
              variant='success'
              onClick={() => track('submit')}
            >
              {t('SUBMIT')}
            </Button>
          </Form.Group>
        }

        <Modal show={show} onHide={handleClose} size='lg'>
          <Terms consent={agreement} locale={i18n.language} t={t} />
        </Modal>
      </div>
    </div>
  );
};

export default ConsentForm;
