import { Language } from '../types/language';

export const languages: Language[] = [
  { abbr: 'ca-ES', name: 'Català', alt: 'ALT_TEXT_ES' },
  { abbr: 'de-DE', name: 'Deutsch', alt: 'ALT_TEXT_DE' },
  { abbr: 'de-CH', name: 'Deutsch', alt: 'ALT_TEXT_CH' },
  { abbr: 'en-GB', name: 'English', alt: 'ALT_TEXT_GB' },
  { abbr: 'en-US', name: 'English', alt: 'ALT_TEXT_US' },
  { abbr: 'en-IE', name: 'English', alt: 'ALT_TEXT_IE' },
  { abbr: 'es-ES', name: 'Español', alt: 'ALT_TEXT_ES' },
  { abbr: 'es-US', name: 'Español', alt: 'ALT_TEXT_US' },
  { abbr: 'fi-FI', name: 'Suomi', alt: 'ALT_TEXT_FI' },
  { abbr: 'fr-CH', name: 'Français', alt: 'ALT_TEXT_CH' },
  { abbr: 'it-IT', name: 'Italiano', alt: 'ALT_TEXT_IT' },
  { abbr: 'it-CH', name: 'Italiano', alt: 'ALT_TEXT_CH' },
  { abbr: 'nl-NL', name: 'Nederlands', alt: 'ALT_TEXT_NL' },
  { abbr: 'pt-PT', name: 'Português', alt: 'ALT_TEXT_PT' },
  { abbr: 'sv-SE', name: 'Svenska', alt: 'ALT_TEXT_SE' },
];

export const getLanguage = (locale: string) => {
  return languages.filter((lang) => lang.abbr === locale)[0];
};

export const getCountryCode = (locale: string): string => {
  return locale?.split('-')[1].toUpperCase();
};

export const getLanguageName = (locale: string) => {
  if (locale === 'en') locale = 'en-US';
  return getLanguage(locale)?.name || 'English';
};
