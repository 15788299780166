import AR from '../assets/images/flags/AR.png';
import AU from '../assets/images/flags/AU.png';
import CA from '../assets/images/flags/CA.png';
import CH from '../assets/images/flags/CH.svg';
import DE from '../assets/images/flags/DE.svg';
import ES from '../assets/images/flags/ES.svg';
import FI from '../assets/images/flags/FI.svg';
import FR from '../assets/images/flags/FR.png';
import GB from '../assets/images/flags/GB.svg';
import IE from '../assets/images/flags/IE.svg';
import IT from '../assets/images/flags/IT.svg';
import JP from '../assets/images/flags/JP.png';
import KR from '../assets/images/flags/KR.png';
import MX from '../assets/images/flags/MX.png';
import NL from '../assets/images/flags/NL.svg';
import PT from '../assets/images/flags/PT.svg';
import RU from '../assets/images/flags/RU.png';
import SE from '../assets/images/flags/SE.svg';
import US from '../assets/images/flags/US.svg';

export const flags = {
  AR,
  AU,
  CA,
  CH,
  DE,
  ES,
  FI,
  FR,
  GB,
  IE,
  IT,
  JP,
  KR,
  MX,
  NL,
  PT,
  RU,
  SE,
  US,
};
