import { ContactInfo } from '../types/contactInfo';

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateField = (
  props: object,
  contactInfo: ContactInfo
): boolean => {
  const name = Object.keys(props)[0];
  const value = Object.values(props)[0];

  switch (name) {
    case 'first_name':
      return value.length > 0 && value.length <= 40;
    case 'last_name':
      return value.length > 0 && value.length <= 80;
    case 'email':
      return value.length > 0 && value.length < 80 && value.match(emailRegex);
    case 'retype_email':
      return value === contactInfo.email;
    case 'Propeller_Code__c':
      return value.length > 0 && value.length < 255;
    case 'Account_Type__c':
      return !!value;
    default:
      return true;
  }
};
