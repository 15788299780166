import { useEffect, useRef, useState } from 'react';
import { initSegment } from '../../utils/segment';
import { SUBDOMAIN, getSFConfig } from '../../utils/environment';
import Layout from '../Layout';
import ContactForm from '../ContactForm/ContactForm';
import ConsentForm from '../ConsentForm/ConsentForm';
import ErrorPage from '../Error/Error';
import fields from '../../utils/fields';
import styles from './home.module.css';
import { getTLD, getGroup } from '../../utils/environment';
import { getGroupConfig } from '../../api';
import { useTranslation } from 'react-i18next';
import { GroupConfig } from '../../types/groupConfig';

export default function Home() {
  const { t } = useTranslation('common');
  const [groupConfig, setGroupConfig] = useState<GroupConfig>();
  const [error, setError] = useState(false);
  const [step, changeStep] = useState<number>(1);
  const [config, setConfig] =
    useState<{ oid: string; url: string; recordType: string }>();
  const topRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    const groupId = getGroup();
    const tld = getTLD();
    if (groupId) {
      getGroupConfig(groupId, tld)
        .then((res) => setGroupConfig(res))
        .catch((err) => setError(err));
    }
  }, []);

  useEffect(() => {
    setConfig(getSFConfig(getTLD() || ''));
  }, []);

  useEffect(() => {
    initSegment('');
  }, []);

  const handleNext = () => {
    if (step === 1) {
      topRef?.current?.focus();
      return changeStep(2);
    }
  };

  if (error) {
    return <ErrorPage />;
  }

  return (
    <Layout groupConfig={groupConfig}>
      {/* eslint-disable-next-line */}
      <a className={styles.skipLink} href='#' ref={topRef}>
        {t('PAGE_TITLE_CONSENT')}
      </a>
      <div className='home'>
        <form method='POST' action={config?.url}>
          <select
            className={styles.Lead_Type__c}
            id='Lead_Type__c'
            name='Lead_Type__c'
          >
            <option>Provider</option>
          </select>
          <input type='hidden' name='oid' value={config?.oid} />
          <input
            type='hidden'
            name='retURL'
            value={`https://${SUBDOMAIN}.propellerhealth.${getTLD()}/thanks`}
          />
          <input
            type='hidden'
            name='Parent_Group__c'
            value={SUBDOMAIN.toString()}
            max-length='255'
          />
          <input
            type='hidden'
            name='recordType'
            id='recordType'
            value={config?.recordType}
          />

          {/* showing and hiding because both components need to be on the page for the salseforce form to work */}

          <div className={`page1 ${step === 1 ? '' : styles.hidden}`}>
            <ContactForm
              fields={fields}
              goNext={handleNext}
              groupConfig={groupConfig}
            />
            <div className={styles.secondary}></div>
          </div>
          <div className={`page2 ${step === 2 ? '' : styles.hidden}`}>
            <ConsentForm groupConfig={groupConfig} />
          </div>
        </form>
      </div>
    </Layout>
  );
}
