import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/Home/Home';
import ThankYou from './components/ThankYou/ThankYou';

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/thanks'>
          <ThankYou />
        </Route>
        <Route path='/'>
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
