import React from 'react';

import { useTranslation } from 'react-i18next';
import styles from './error.module.css';

export default function Error() {
  const { t } = useTranslation('common');
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.main}>
          <h1>{t('ERROR_HEADLINE')}</h1>
          <p>
            {t('ERROR_MESSAGE')}{' '}
            <a
              className={styles.link}
              href='mailto:technicalservices@propellerhealth.com'
            >
              technicalservices@propellerhealth.com
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
