const DEV_API_ROOT = 'https://dev.propellerhealth.biz';
const TEST_API_ROOT = 'https://test.propellerhealth.io';
const PROD_API_ROOT = 'https://my.propellerhealth.com';
const EU_API_ROOT = 'https://api.propellerhealth.eu';
const DEMO_API_ROOT = 'https://api.propellerhealth.onl';
const SNDBX_API_ROOT = 'https://api.propellerhealth.org';
const JP_API_ROOT = 'https://api.propellerhealth.jp';

function getApiRoot(tld: string): string {
  switch (tld) {
    case 'biz':
      return DEV_API_ROOT;
    case 'com':
      return PROD_API_ROOT;
    case 'eu':
      return EU_API_ROOT;
    case 'io':
      return TEST_API_ROOT;
    case 'jp':
      return JP_API_ROOT;
    case 'onl':
      return DEMO_API_ROOT;
    case 'org':
      return SNDBX_API_ROOT;
    default:
      return '';
  }
}

export function getGroupConfig(
  groupId: string,
  tld: string | undefined = 'biz'
) {
  if ('signup' === groupId) groupId = 'try';
  const apiRoot = getApiRoot(tld);
  return fetch(`${apiRoot}/api/groups/${groupId}/config`, {
    headers: {
      'x-ph-api-version': '3.95.0',
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((res) => {
      // error messages are returned in an "elements" array
      if (res.elements) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
}
