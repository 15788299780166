export const HAS_WINDOW = 'undefined' !== typeof window;

const HOSTNAME = HAS_WINDOW && window.location.hostname;
export const SUBDOMAIN = HOSTNAME && HOSTNAME.split('.')[0];

export function getTLD() {
  const tld = window.location.hostname.split('.').pop();
  if (tld === 'localhost') return 'biz';
  return tld;
}

export function getGroup() {
  const subdomain = window.location.hostname.split('.')[0];
  return subdomain.split('-')[1];
}

export const getEnv = (tld: string) => {
  return tld === 'com' || tld === 'eu' ? 'prod' : tld === 'io' ? 'test' : 'dev';
};

interface configObject {
  oid: string;
  url: string;
  recordType: string;
}
const SFConfig = {
  dev: {
    oid: '00D1g000000ATET',
    url: 'https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
    recordType: '0121g000003WxAB',
  },
  test: {
    oid: '00D790000004f7i',
    url: 'https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
    recordType: '01279000000GunH',
  },
  prod: {
    oid: '00DU0000000YL5g',
    url: 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
    recordType: '0124U00000038v6',
  },
};

export const getSFConfig = (tld: string): configObject => {
  const env = getEnv(tld);
  return SFConfig[env];
};
