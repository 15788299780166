const root = 'https://ph-legal.s3-us-west-2.amazonaws.com/provider';

export const getPrivacyPolicy = (locale: string) => {
  if (locale) {
    const country = locale === 'en-IE' ? 'GB' : locale.split('-')[1];
    const lang = locale === 'en-IE' ? 'en-GB' : locale;
    return `${root}/privacyPolicy/default/${country}/${lang}.html`;
  }
};

export const getTermsAndConditions = (locale: string) => {
  if (locale) {
    const country = locale === 'en-IE' ? 'GB' : locale.split('-')[1];
    const lang = locale === 'en-IE' ? 'en-GB' : locale;
    return `${root}/termsAndConditions/default/${country}/${lang}.html`;
  }
};

export const getDataAgreement = (locale: string) => {
  if (locale) {
    const country = locale === 'en-IE' ? 'GB' : locale.split('-')[1];
    const lang = locale === 'en-IE' ? 'en-GB' : locale;
    return `${root}/DPA/default/${country}/EU-DPA-Propeller-Providers-Template_${lang}.html`
  }
};
