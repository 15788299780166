// AU and KR use US support phone info; IE uses UK support phone info
import { SupportPhone, SupportPhones } from '../types/supportPhone';

const supportPhones: SupportPhones = {
  AR: {
    close: '20:00',
    open: '8:00',
    phone: '+54 0800 222 0393',
    timezone: 'TIMEZONE_ART'
  },
  AU: {
    close: '8:00 PM',
    open: '8:00 AM',
    phone: '+1 (877) 251-5451',
    timezone: 'TIMEZONE_EST'
  },
  CA: {
    close: '8:00 PM',
    open: '8:00 AM',
    phone: '+1 877-251-5451',
    timezone: 'TIMEZONE_EST'
  },
  FR: {
    close: '20:00',
    open: '8:00',
    phone: '+33 1 77 51 33 83',
    timezone: 'TIMEZONE_CET'
  },
  DE: {
    close: '20:00',
    open: '8:00',
    phone: '+49 89 41207201',
    timezone: 'TIMEZONE_CET'
  },
  ES: {
    close: '20:00',
    open: '8:00',
    phone: '+34 902 757 367',
    timezone: 'TIMEZONE_CET'
  },
  GB: {
    close: '20:00',
    open: '8:00',
    phone: '+44 1557 520409',
    timezone: 'TIMEZONE_WET'
  },
  IE: {
    close: '20:00',
    open: '8:00',
    phone: '+44 1557 520409',
    timezone: 'TIMEZONE_WET'
  },
  IT: {
    close: '20:00',
    open: '8:00',
    phone: '+39 800797077',
    timezone: 'TIMEZONE_CET'
  },
  MX: {
    close: '20:00',
    open: '8:00',
    phone: '+52 55 5350 9447',
    timezone: 'TIMEZONE_MCT'
  },
  NL: {
    close: '20:00',
    open: '8:00',
    phone: '+31 850014667',
    timezone: 'TIMEZONE_CET'
  },
  PT: {
    close: '20:00',
    open: '8:00',
    phone: '+351 308802362',
    timezone: 'TIMEZONE_WET'
  },
  US: {
    close: '8:00 PM',
    open: '8:00 AM',
    phone: '+1 (877) 251-5451',
    timezone: 'TIMEZONE_EST'
  }
};

export const getSupportPhone = (countryCode: string): SupportPhone => {
  return supportPhones[countryCode] || supportPhones['US'];
};
