import React from 'react';
import { useTranslation } from 'react-i18next';
import { getSupportPhone } from '../../utils/support-phone';
import { GroupConfig } from '../../types/groupConfig';
import styles from './footer.module.css';
import phLogo from '../../assets/images/logo_blue.svg';

export default function Footer({ groupConfig }: { groupConfig?: GroupConfig }) {
  const { t } = useTranslation('common');
  const { close, open, phone, timezone } = getSupportPhone(
    groupConfig?.country || 'US'
  );
  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <img src={phLogo} alt='Propeller Logo' width='256' height='55' />
        <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
          <li>{t('QUESTIONS_WE_ARE_HERE_TO_HELP')}</li>
          <li>
            {phone} {t('MONDAY_FRIDAY')} {open} - {close} {t(timezone)}
          </li>
          <li>
            <a className={styles.link} href='mailto:help@propellerhealth.com'>
              help@propellerhealth.com
            </a>
          </li>
          <li>
            {' '}
            <a
              className={styles.link}
              href='https://support.propellerhealth.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('SUPPORT_CENTER')}
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
