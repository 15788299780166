import React from 'react';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { GroupConfig } from '../types/groupConfig';
import styles from './shared.module.css';

export default function Layout({
  children,
  groupConfig,
}: {
  children: React.ReactNode;
  groupConfig?: GroupConfig;
}) {
  return (
    <div className={styles.layout}>
      <Header groupConfig={groupConfig} />
      <main className={styles.main}>{children}</main>
      <Footer groupConfig={groupConfig} />
    </div>
  );
}
