const HAS_WINDOW = 'undefined' !== typeof window;
const HOSTNAME = HAS_WINDOW && window.location.hostname;
const SUBDOMAIN = HOSTNAME && HOSTNAME.split('.')[0];
const TLD =
  'development' === process.env.NODE_ENV
    ? 'biz'
    : HOSTNAME && HOSTNAME.split('.').pop();

const DEV_WRITE_KEY = 'KaW2SuuBYKRuBJc3PvZiwT2eKnVIxTnT';
const TEST_WRITE_KEY = '41uJSNbFVSGdIafclAHfQBpBqQ2AcZAi';
const PROD_WRITE_KEY = 'RTsoPFPHmgTzfoOFh8GFMY7OsC4s2mda';

declare global {
  interface Window {
    analytics: any;
  }
}

export const initSegment = (id: string) => {
  if (window.analytics) return;

  const write_key =
    TLD === 'com'
      ? PROD_WRITE_KEY
      : TLD === 'io'
      ? TEST_WRITE_KEY
      : DEV_WRITE_KEY;
  if (window) {
    const init = function () {
      var analytics = (window.analytics = window.analytics || []);
      if (!analytics.initialize)
        if (analytics.invoked)
          window.console &&
            console.error &&
            console.error('Segment snippet included twice.');
        else {
          analytics.invoked = !0;
          analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'debug',
            'page',
            'once',
            'off',
            'on',
          ];
          analytics.factory = function (t: any) {
            return function () {
              var e = Array.prototype.slice.call(arguments);
              e.unshift(t);
              analytics.push(e);
              return analytics;
            };
          };
          for (var t = 0; t < analytics.methods.length; t++) {
            var e = analytics.methods[t];
            analytics[e] = analytics.factory(e);
          }
          analytics.load = function (t: any, e: any) {
            var n = document.createElement('script');
            n.type = 'text/javascript';
            n.async = !0;
            n.src =
              'https://cdn.segment.com/analytics.js/v1/' +
              t +
              '/analytics.min.js';
            var a = document.getElementsByTagName('script')[0];
            if (a && a.parentNode) {
              a.parentNode.insertBefore(n, a);
            }
            analytics._loadOptions = e;
          };
          analytics.SNIPPET_VERSION = '4.1.0';
          analytics.load(write_key);
          analytics.identify(id, {}, { context: { ip: '0.0.0.0' } });
          analytics.page('Home');
        }
    };
    init();
  }
};

export const track = (action: string) => {
  if (window && window.analytics) {
    window.analytics.track(
      'CTA_CLICKED',
      {
        action: action,
        subdomain: SUBDOMAIN,
      },
      { context: { ip: '0.0.0.0' } }
    );
  }
};
