import React, { FunctionComponent } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';

import { Language } from '../../types/language';
import {
  languages,
  getCountryCode,
  getLanguage,
  getLanguageName,
} from '../../utils/locales';
import styles from './language-selector.module.css';
import { flags } from '../../utils/flags';

const Flag: FunctionComponent<{ lang: Language }> = ({ lang }) => {
  const country = getCountryCode(lang?.abbr) || 'US';
  const image = flags[country as keyof typeof flags];

  return (
    <img
      alt={country}
      className={styles.img}
      src={image}
      width='24'
      height='24'
    />
  );
};

interface LanguageSelectorProps {
  country: string | undefined;
}

export function LanguageSelector({ country }: LanguageSelectorProps) {
  const { i18n, t } = useTranslation('common');

  const selectLanguage = (locale: string) => {
    i18n.changeLanguage(locale);
  };
  const language = getLanguage(i18n.language);
  const languageName = getLanguageName(i18n.language);
  return (
    <Dropdown className={styles.languageSelector} alignRight>
      <Dropdown.Toggle
        className={styles.language}
        data-testid='dropdown-toggle'
      >
        <Flag lang={language} />
        <div className={styles.languageName}>{languageName}</div>
      </Dropdown.Toggle>
      &nbsp;
      <Dropdown.Menu>
        {languages.map((lang) => {
          const image = flags[lang.abbr.split('-')[1] as keyof typeof flags];
          return (
            <Dropdown.Item
              className={styles.dropdownItem}
              data-testid='dropdown-item'
              key={lang.abbr}
              as='button'
              onClick={() => selectLanguage(lang.abbr)}
            >
              <>
                <img
                  alt={t(lang.alt)}
                  className={styles.img}
                  src={image}
                  width='24'
                  height='24'
                />
                <div className={styles.itemName}>{lang.name}</div>
              </>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LanguageSelector;
