const fields = [
  {
    type: 'text',
    error_message: 'PROVIDE_FIRST_NAME',
    label: 'FIRST_NAME',
    maxLength: 40,
    name: 'first_name',
    placeholder: 'Jane',
    required: true,
  },
  {
    type: 'text',
    error_message: 'PROVIDE_LAST_NAME',
    label: 'LAST_NAME',
    maxLength: 80,
    name: 'last_name',
    placeholder: 'Smith',
    required: true,
  },
  {
    type: 'text',
    error_message: '',
    label: 'TITLE',
    maxLength: 80,
    name: 'title',
    placeholder: '',
    required: false,
  },
  {
    type: 'text',
    error_message: 'PROVIDE_EMAIL',
    label: 'EMAIL',
    maxLength: 80,
    name: 'email',
    placeholder: 'user@example.com',
    required: true,
  },
  {
    type: 'text',
    error_message: 'EMAILS_DONT_MATCH',
    label: 'RETYPE_EMAIL',
    maxLength: 80,
    name: 'retype_email',
    placeholder: '',
    required: true,
  },
  {
    type: 'text',
    error_message: 'PROVIDE_PROPELLER_CODE',
    label: 'LABEL_PROPELLER_CODE',
    maxLength: 225,
    name: 'Propeller_Code__c',
    placeholder: '',
    required: true,
  },
  {
    type: 'select',
    error_message: 'ACCOUNT_TYPE_ERROR',
    label: 'ACCOUNT_TYPE',
    maxLength: 255,
    name: 'Account_Type__c',
    placeholder: '',
    required: true,
    options: [
      {label: 'ACCOUNT_TYPE_OPTION_1', value: ''},
      {label: 'ACCOUNT_TYPE_OPTION_2', value: 'Admin'},
      {label: 'ACCOUNT_TYPE_OPTION_3', value: 'HCP'},
    ]
  },
  {
    type: 'text',
    error_message: '',
    label: 'SITE_ID',
    maxLength: 255,
    name: 'Site_ID__c',
    placeholder: '',
    required: false
  },
  {
    type: 'text',
    error_message: '',
    label: 'CLINIC_NAME',
    maxLength: 255,
    name: 'Clinic_Name__c',
    placeholder: '',
    required: false
  }
];

export default fields;
