import React, { useEffect } from 'react';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { GroupConfig } from '../../types/groupConfig';
import styles from './header.module.css';
import phLogo from '../../assets/images/logo_blue.svg';

export default function Header({ groupConfig }: { groupConfig?: GroupConfig }) {
  useEffect(() => {
    function addScript(src: any, attr: any) {
      var head = document.getElementsByTagName('head')[0];
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.onload = function () {
        //script has been loaded
      }
      script.src = src;
      for (var i = 0; i < attr.length; i++) {
        script.setAttribute(attr[i].key, attr[i].value);
      }
      head.appendChild(script);
    }
    if (window.location.host.includes('.biz') || window.location.host.includes('localhost:3000')) {
      addScript("https://cdn.cookielaw.org/consent/c850c414-86ff-46cf-9583-3b2bcb39649b/OtAutoBlock.js", []);
      addScript("https://cdn.cookielaw.org/scripttemplates/otSDKStub.js", [{ key: 'charset', value: 'UTF-8' }, { key: 'data-domain-script', value: 'c850c414-86ff-46cf-9583-3b2bcb39649b' }]);
    } else {
      addScript("https://cdn.cookielaw.org/consent/c850c414-86ff-46cf-9583-3b2bcb39649b/OtAutoBlock.js", []);
      addScript("https://cdn.cookielaw.org/scripttemplates/otSDKStub.js", [{ key: 'charset', value: 'UTF-8' }, { key: 'data-domain-script', value: 'eb4ad593-ee82-4795-a05f-d43bd25504f6' }]);
    }
  }, [])

  const logo = groupConfig?.assets?.find((obj) => obj.name === 'logo');

  return (
    <header>
      <div className={`${styles.header} ${styles.wrapper}`}>
        <div className={styles.logo}>
          {logo ? (
            <div className={styles.partnerLogo}>
              <img src={logo.url} width='100%' alt={groupConfig?.displayName} />
            </div>
          ) : null}
          <a className={styles.phLogo} href='/'>
            <img src={phLogo} width='256' alt='Propeller' />
          </a>
        </div>
        <LanguageSelector country={groupConfig?.country} />
      </div>
    </header>
  );
}
