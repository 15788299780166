import { useState, ChangeEvent } from 'react';
import Form from 'react-bootstrap/Form';
import styles from './select.module.css';
interface OnChangeFunc {
  (props: object): void;
}

interface SelectProps {
  error: boolean;
  errorMessage: string;
  label: string;
  name: string;
  onChange?: OnChangeFunc;
  required: boolean;
  options: any;
  t: any;
}

export default function Select({
  error,
  errorMessage,
  label,
  name,
  onChange,
  required,
  options,
  t,
}: SelectProps) {
  const [value, setValue] = useState<string>('');
  const [valid, setValid] = useState<boolean>();
  const [invalid, setInvalid] = useState<boolean>();

  /* useEffect(() => {
    setInvalid(value === '');
  }, [value]) */

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValid(false);
    setInvalid(false);
    setValue(value);
    if (onChange) {
      onChange({ [name]: value });
    }
  };

  return (
    <Form.Group className={styles.formGroup} controlId={name}>
      <Form.Label className={styles.label}>{t(label)}</Form.Label>
      <Form.Control 
        as="select"
        className={styles.formControl} 
        isInvalid={invalid || error}
        isValid={valid}
        name={name}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
        required={required}
        value={ value }
        >
        {options.map((option: any) => (
          <option key={ option.label } value={ option.value }>{ t(option.label) }</option>
        ))}
      </Form.Control>
      <div className={styles.errorMessage} role='alert' aria-live='assertive'>
        {invalid || error ? t(errorMessage) : null}
      </div>
    </Form.Group>
  );
}
